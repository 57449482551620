import Vue from 'vue'
import App from './App.vue'
import router from '@/router'

import MetaInfo from 'vue-meta-info'
import foot from "@/components/footer.vue"
Vue.use(MetaInfo)
Vue.component('foot', foot)
Vue.config.productionTip = false

new Vue({
	render: h => h(App),
	router
}).$mount('#app')
