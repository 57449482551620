import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import "nprogress/nprogress.css";
Vue.use(VueRouter)


const router = new VueRouter({
	mode: 'history',
	routes: [{
			path: '/',
			component: () => import('@/pages/index'),
			meta: {
				savedPosition: 0
			}
		}, {
			path: '/about.html',
			component: () => import('@/pages/about'),
			meta: {
				savedPosition: 0
			}
		},
		{
			path: '/product.html',
			component: () => import('@/pages/product'),
			meta: {
				savedPosition: 0
			}
		}, {
			path: '/case.html',
			component: () => import('@/pages/case'),
			meta: {
				savedPosition: 0
			}
		}
	],
	scrollBehavior(to, from, savedPosition) {
		console.log(savedPosition)
		if (savedPosition) {
			return savedPosition
		} else {
			console.log(to.meta.savedPosition)
			return {
				x: 0,
				y: to.meta.savedPosition || 0
			}
		}
	}

})
//全局路由前置守卫
router.beforeEach((to, from, next) => {
	console.log('全局路由前置守卫')
	from.meta.savedPosition = document.documentElement.scrollTop || document.body.scrollTop;
	if (to.meta.savedPosition == 0) {
		NProgress.start()
	}
	next();

});

router.afterEach(() => {
	NProgress.done()
})
export default router
