<template>
	<div id="app">
		<navbar></navbar>

		<router-view></router-view>

	</div>
</template>

<script>
	import navbar from "@/components/navbar"

	export default {
		name: 'app',
		components: {
			navbar
		}
	}
</script>

<style>
	@import '../src/css/flex.css';

	#app {
		padding-top: 81px;
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin: 0 auto;

	}

	body {
		margin: 0;
	}

	#nprogress .bar {
		z-index: 10000 !important;
	}

	#nprogress .spinner {
		 
		z-index: 10000 !important;
	}
	#nprogress .spinner .spinner-icon{
		display: none;
	}
</style>
